<template>
  <Form ref="form" :submit="handleSubmit" :initialValues="getInitialValues(initialValues)">
    <div class="form-narrow">
      <div class="form-row">
        <TextField
          name="key"
          label="data key name*"
          placeholder="key"
          :editMode="isNewDataKey"
          :validate="[required, alphaNumericKey, maxLength(100)]"
        />
        <div class="form-col">
          <label class="label">specification*</label>
          <div class="flex items-center">
            <div :class="{'flex-1': isNewDataKey}">
              <SelectInput
                name="specification"
                placeholder="specification"
                :options="specificationOptions"
                :validate="required"
                :editMode="isNewDataKey"
                objectMode
              />
            </div>
            <div class="ml-2">
              <specification-info
                v-if="specificationSelected"
                :properties="currentSpecification.properties"
                :isNullable="currentSpecification.isNullable"
                positionX="right"
              />
              <Tooltip v-else icon="info" text="select a specification" positionX="right" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <TextField
          name="description"
          label="description*"
          placeholder="description"
          :validate="[required, maxLength(400)]"
          :editMode="editable"
        />
      </div>
      <ModalFooter :footer="footer" :tertiary="cancel" />
    </div>
  </Form>
</template>

<script>
  import {isEmpty} from 'lodash-es';
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import Form from '@/components/form/Form';
  import TextField from '@/components/form/TextField';
  import SelectInput from '@/components/form/SelectInput';
  import SpecificationInfo from '@/components/auth/data_block/SpecificationInfo';
  import Tooltip from '@/components/ui/Tooltip';

  export default {
    name: "SharedDataKeyForm",
    components: {
      Tooltip,
      Form,
      ModalFooter,
      TextField,
      SelectInput,
      SpecificationInfo,
    },
    mixins: [
      ModalNavigation,
      ValidatorMixin,
    ],
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({})
      },
    },
    computed: {
      specificationSelected() {
        return !isEmpty(this.currentSpecification);
      },
      specificationOptions() {
        return this.specifications.map((s) => ({ key: s.id, value: s.name.toLocaleLowerCase(), item: s }));
      },
      isNewDataKey() {
        return !this.initialValues.key;
      },
      editable() {
        return !this.initialValues.deletedAt;
      },
      footer() {
        if (this.editable) {
          return {
            primaryButton: 'save',
            tertiaryButton: 'cancel'
          };
        }

        return {
          tertiaryButton: 'back'
        };
      },
    },
    data: function () {
      return {
        specifications: [],
        currentSpecification: {},
      }
    },
    methods: {
      getInitialValues(values) {
        const { specificationId } = values;
        if (specificationId) {
          return {
            ...values,
            specification: this.specificationOptions.find(({ key }) => key === specificationId),
          }
        }

        return values;
      },
      handleSubmit({ specification, ...values }) {
        this.onSubmit({
          ...values,
          specificationId: specification.key,
        })
      },
      fetchSpecifications() {
        this.$raaDataProvider.getList('specifications', { size: 999 })
          .then(({content}) => {
            this.specifications = content;
          })
          .catch((error) => this.notifyError(error.message))
      },
      cancel(e) {
        e.preventDefault();
        this.close();
      }
    },
    mounted() {
      this.fetchSpecifications();

      this.$watch(
        () => this.$refs.form?.formState.values?.specification,
        specification => {
          this.currentSpecification = specification?.item || {};
        }
      );
    },
  }
</script>

<style scoped>

</style>
